import { Helmet } from "react-helmet-async";
import { Bar, BarChart, CartesianGrid, XAxis, Label, Pie, PieChart, Sector, Line, LineChart } from "recharts";
import { ChartContainer, ChartLegend, ChartLegendContent, ChartTooltip, ChartTooltipContent } from "../../components/Chart";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../components/Card";
import { useMemo, useState } from "react";
import { formatCurrency } from "../../data/utils";

const Dashboard = ({ authState }) => {
  const generateRandomValue = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const chartData = [
    { month: "Dec 11th", created: generateRandomValue(250, 350), collected: generateRandomValue(100, 250) },
    { month: "Dec 12th", created: generateRandomValue(250, 350), collected: generateRandomValue(100, 250) },
    { month: "Dec 13th", created: generateRandomValue(250, 350), collected: generateRandomValue(100, 250) },
    { month: "Dec 14th", created: generateRandomValue(250, 350), collected: generateRandomValue(100, 250) },
    { month: "Dec 15th", created: generateRandomValue(250, 350), collected: generateRandomValue(100, 250) },
    { month: "Dec 16th", created: generateRandomValue(250, 350), collected: generateRandomValue(100, 250) },
    { month: "Dec 17th", created: generateRandomValue(250, 350), collected: generateRandomValue(100, 250) },
  ];

  const chartConfig = {
    created: {
      label: "Created",
      color: "#fde047",
    },
    collected: {
      label: "Collected",
      color: "#84cc16",
    },
  };

  const pieChartConfig = {
    paid: {
      label: "Paid",
      color: "#84cc16",
    },
    sent: {
      label: "Sent",
      color: "#fde047",
    },
    overdue: {
      label: "Overdue",
      color: "#ef4444",
    },
  };

  const desktopData = [
    { status: "paid", quantity: generateRandomValue(20, 350), amount: generateRandomValue(120, 280) * 1000, fill: "var(--color-paid)" },
    { status: "sent", quantity: generateRandomValue(20, 350), amount: generateRandomValue(120, 280) * 1000, fill: "var(--color-sent)" },
    { status: "overdue", quantity: generateRandomValue(20, 350), amount: generateRandomValue(120, 280) * 1000, fill: "var(--color-overdue)" },
  ];

  let currencyFormatter = Intl.NumberFormat("en", { notation: "compact", style: "currency", currency: "USD" });

  const salesData = [
    { date: "Dec 11th", thisWeek: generateRandomValue(20, 350), lastWeek: generateRandomValue(20, 350) },
    { date: "Dec 12th", thisWeek: generateRandomValue(20, 350), lastWeek: generateRandomValue(20, 350) },
    { date: "Dec 13th", thisWeek: generateRandomValue(20, 350), lastWeek: generateRandomValue(20, 350) },
    { date: "Dec 14th", thisWeek: generateRandomValue(20, 350), lastWeek: generateRandomValue(20, 350) },
    { date: "Dec 15th", thisWeek: generateRandomValue(20, 350), lastWeek: generateRandomValue(20, 350) },
    { date: "Dec 16th", thisWeek: generateRandomValue(20, 350), lastWeek: generateRandomValue(20, 350) },
    { date: "Dec 17th", thisWeek: generateRandomValue(20, 350), lastWeek: generateRandomValue(20, 350) },
  ];
  const salesChartConfig = {
    thisWeek: {
      label: "This Week",
      color: "#60a5fa",
    },
    lastWeek: {
      label: "Last Week",
      color: "#d4d4d8",
    },
  };
  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <Helmet>
        <title>Dashboard | Wrightsboro Supply ERP</title>
      </Helmet>
      <div className="grid w-full grid-cols-3 gap-6 mt-6">
        <Card className="col-span-2">
          <CardHeader>
            <CardTitle>Transaction Overview</CardTitle>
            <CardDescription className="text-xs opacity-60">Comparison of created and collected transactions over the past week</CardDescription>
          </CardHeader>
          <CardContent>
            <ChartContainer config={chartConfig} className="max-h-[260px] w-full">
              <BarChart accessibilityLayer data={chartData}>
                <CartesianGrid vertical={false} className="opacity-30" />
                <XAxis dataKey="month" tickLine={false} tickMargin={10} axisLine={false} tickFormatter={(value) => value} />
                <ChartTooltip content={<ChartTooltipContent />} />
                <ChartLegend content={<ChartLegendContent />} />
                <Bar dataKey="created" fill="var(--color-created)" radius={4} />
                <Bar dataKey="collected" fill="var(--color-collected)" radius={4} />
              </BarChart>
            </ChartContainer>
          </CardContent>
        </Card>
        <Card className="col-span-1">
          <CardHeader>
            <CardTitle>Invoice volume by status</CardTitle>
          </CardHeader>
          <CardContent>
            <ChartContainer id="invoice-volume-by-sales" config={pieChartConfig} className="w-full mx-auto aspect-square max-h-[260px]">
              <PieChart>
                <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
                <Pie
                  data={desktopData}
                  dataKey="quantity"
                  nameKey="status"
                  innerRadius={60}
                  strokeWidth={5}
                  activeIndex={0}
                  activeShape={({ outerRadius = 0, ...props }) => (
                    <g>
                      <Sector {...props} outerRadius={outerRadius + 5} />
                      <Sector {...props} outerRadius={outerRadius + 3} innerRadius={outerRadius + 3} />
                    </g>
                  )}
                >
                  <Label
                    content={({ viewBox }) => {
                      if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                        return (
                          <text x={viewBox.cx} y={viewBox.cy} textAnchor="middle" dominantBaseline="middle">
                            <tspan x={viewBox.cx} y={viewBox.cy} className="text-2xl font-bold fill-foreground">
                              {currencyFormatter.format(desktopData.reduce((acc, item) => acc + item.amount, 0))}
                            </tspan>
                            <tspan x={viewBox.cx} y={(viewBox.cy || 0) + 24} className="fill-muted-foreground">
                              Total
                            </tspan>
                          </text>
                        );
                      }
                    }}
                  />
                </Pie>
                <ChartLegend content={<ChartLegendContent />} />
              </PieChart>
            </ChartContainer>
          </CardContent>
        </Card>
        <Card className="col-span-3">
          <CardHeader>
            <CardTitle>Total Sales</CardTitle>
            <CardDescription className="text-xs opacity-60">Last 7 days compared to previous 7 days</CardDescription>
          </CardHeader>
          <CardContent>
            <ChartContainer config={salesChartConfig} className="max-h-[280px] w-full">
              <LineChart
                accessibilityLayer
                data={salesData}
                margin={{
                  left: 12,
                  right: 12,
                }}
              >
                <CartesianGrid vertical={false} className="opacity-30" />
                <XAxis dataKey="date" tickLine={false} axisLine={false} tickMargin={8} />
                <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
                <Line dataKey="thisWeek" type="monotone" stroke="var(--color-thisWeek)" strokeWidth={2} dot={false} />
                <Line dataKey="lastWeek" type="monotone" stroke="var(--color-lastWeek)" strokeWidth={2} dot={false} />
                <ChartLegend content={<ChartLegendContent />} />
              </LineChart>
            </ChartContainer>
          </CardContent>
        </Card>
        <p className="col-span-3 text-xs font-semibold text-center text-zinc-400">Dummy data - for illustrative purposes only</p>
      </div>
    </div>
  );
};

export default Dashboard;